<template>
    <div class="page">
        <LiefengContent :isBack="route.isBack()" @backClick="route.back()">
            <template #title>{{ title }}</template>
            <template #contentArea>
                <div class="content-box">
                    <div class="block-box type-box">
                        <div class="total-number">
                            <p class="name">60岁以上老人总数：</p>
                            <p class="value" @click="jump('/residentdatabasetable')">{{ userCount || 0 }}</p>
                        </div>
                        <div class="statistics-box" v-if="userTypeData">
                            <div class="item-box" v-for="(item, idx) in userTypeData" :key="'userTypeCountList' + idx" @click="onJumpTableByAppId(item)">
                                <p class="title">{{ item.typeName }}</p>
                                <p class="value">{{ item.typeCount }}</p>
                                <div class="proportion">
                                    <p>占社区</p>
                                    <p>{{ item.percent }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ElemTable :columns="columns" :dataList="communitys"></ElemTable>
                </div>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import ElemTable from "../communitymobilization/components/ElemTable.vue"

import Route from "../communitymobilization/entity/Route"
import Street from "./utils/street"
import Utils from "../jointly/utils/utils"

export default {
    components: {
        LiefengContent,
        ElemTable,
    },

    data() {
        return {
            route: Route,
            title: "老人数据统计",
            userCount: 0,
            userTypeData: [],

            columns: [
                {
                    title: "社区",
                    key: "communityName",
                },
                {
                    title: "60-69岁",
                },
                {
                    title: "70-79岁",
                },
                {
                    title: "80-89岁",
                },
                {
                    title: "90岁以上",
                },
                {
                    title: "汇总",
                    key: "total",
                },
            ],

            communitys: null,
        }
    },

    created() {
        this.getUserStatistics()
    },

    methods: {
        async getUserStatistics() {
            let street = await Street.getInfo()

            this.$get("/gateway/api/symanage/pc/syuser/listUserAgeByStreetCode", {
                streetCode: street.orgCode,
            }).then(res => {
                if (res.code != 200) {
                    return this.$Message.error(res.desc)
                }

                const keys = ["60-69岁", "70-79岁", "80-89岁", "90岁以上"]

                var count = {
                    "60-69岁": 0,
                    "70-79岁": 0,
                    "80-89岁": 0,
                    "90岁以上": 0,
                }

                for (let i = 0, l = res.data.statResultVos; i < l.length; i++) {
                    let v = l[i]

                    if (typeof count[v.key] !== "undefined") {
                        count[v.key] = v
                    }
                }

                Utils.eachObj(count, (k, v) => {
                    this.userCount += v.value

                    this.userTypeData.push({
                        typeName: k,
                        typeCount: v.value,
                        percent: v.ext,
                    })
                })

                const communitys = []

                Utils.each(res.data.orgReportDataVos, v => {
                    var total = 0

                    var item = {
                        communityName: v.orgName,
                    }

                    Utils.each(v.userTypeCountList, l => {
                        if (keys.indexOf(l.typeName) > -1) {
                            item[l.typeName] = l.typeCount
                            total += l.typeCount
                        }
                    })

                    item.total = total

                    communitys.push(item)
                })

                this.communitys = communitys
            })
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    .content-box {
        max-width: 1200px;
        margin: 0 auto;
    }

    .block-box {
        width: 100%;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        margin: 20px 0;

        &:last-child {
            margin-bottom: 0;
        }

        > .title {
            padding: 10px 0;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .entrance {
                cursor: pointer;
                font-size: 14px;
                font-weight: normal;
            }
        }

        &.type-box {
            .total-number {
                margin-bottom: 10px;
                display: flex;
                align-items: flex-end;

                .name {
                    font-size: 18px;
                    color: #666;
                }

                .value {
                    cursor: pointer;
                    font-size: 36px;
                    line-height: 28px;
                    font-weight: bold;
                    color: #29cb97;
                }
            }

            .statistics-box {
                display: flex;
                margin: 20px 0 10px 0;
                flex-wrap: wrap;

                > .item-box {
                    cursor: pointer;
                    position: relative;
                    width: calc(100% / 4 - 10px);
                    min-width: 120px;
                    padding: 10px 15px;
                    margin: 5px;
                    height: 140px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    background: #f4fafd;
                    border-radius: 10px;
                    border: 1px solid #f5f5f5;
                    box-sizing: border-box;

                    &:last-child::after {
                        background: initial;
                    }

                    > .title {
                        font-size: 14px;
                        color: #666;
                        line-height: 25px;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        max-width: 100%;
                    }

                    .value {
                        font-size: 45px;
                        font-weight: bold;
                        color: #0091ff;
                    }

                    .proportion {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        color: #999;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
